import "./MailLog.css"

import React, { useContext, useEffect, useState } from "react"
import { AutoComplete, Modal, Menu, Dropdown, Table, Input, Button, Space, Typography } from "antd"
import { DatePicker } from "antd"
import { SearchOutlined, DownOutlined } from "@ant-design/icons"
import moment from "moment-timezone"

// get global state context
import { GlobalStoreContext } from "../../Store"
import apiConstants from "../../api"
import constants from "../../constants"
const { RangePicker } = DatePicker
const { Text } = Typography

const MailLog = (props) => {
	// get mutations and state variables from global state
	const { setMenuToolBarTitle, getMailLogRows, getStockistEntries, updateStockist, failMessage, successMessage } =
		useContext(GlobalStoreContext)

	const [selectedClient, changeClient] = useState(apiConstants.SSE_CLIENTS[0])
	const [rows, setRows] = useState([])
	const [dates, setDates] = useState([moment().tz("Asia/Kolkata").set("date",25).subtract(1, "month"), moment().tz("Asia/Kolkata").set("date", 25)])
	const [searchObj, setSearchObj] = useState({})
	const [loading, toggleLoading] = useState(true)
	const [currentPageNo, setPageNo] = useState(1)
	const [currentPageSize, setPageSize] = useState(7)
	const [currentTotal, setCurrentTotal] = useState(0)

	// when component mounted
	useEffect(() => {
		// set the menu tool bar title text
		setMenuToolBarTitle("Search Mail Log")
	}, [setMenuToolBarTitle])

	const handleMenuItemClick = function (e) {
		changeClient(apiConstants.SSE_CLIENTS.find((c) => c.name === e.key.split("_")[0]))
	}

	// watcher
	useEffect(() => {
		toggleLoading(true)
		let mounted = true
		getMailLogRows({
			client: selectedClient,
			filter: searchObj,
			dates,
			pagination: { currentPageNo, currentPageSize },
		})
			.then((response) => {
				if (mounted) {
					setRows(
						response.rows.map((e) => {
							e.hide = e.hide !== undefined ? String(e.hide) : "false"
							e.mapped = e.mapped !== undefined ? String(e.mapped) : "false"
							e.reintroduced = e.reintroduced !== undefined ? String(e.reintroduced) : "false"
							e.date = moment(e.date).tz("Asia/Kolkata").format("DD MMMM YYYY HH:mm:ss")
							return e
						})
					)
					setCurrentTotal(response.total)
					toggleLoading(false)
				}
			})
			.catch((e) => {
				if (mounted) {
					failMessage(e.message)
					toggleLoading(false)
				}
			})

		return () => (mounted = false)
		// eslint-disable-next-line
	}, [dates, selectedClient, searchObj, currentPageNo, currentPageSize])

	const menu = (
		<Menu>
			{apiConstants.SSE_CLIENTS.map((e, idx) => {
				return (
					<Menu.Item key={e.name + "_" + idx} onClick={handleMenuItemClick}>
						{e.name}
					</Menu.Item>
				)
			})}
		</Menu>
	)

	const getColumnSearchProps = (dataIndex) => {
		if (dataIndex === "date") return null
		return {
			filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
				<div style={{ padding: 8 }}>
					<Input
						placeholder={`Search ${dataIndex}`}
						value={selectedKeys[0]}
						onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
						onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
						style={{
							width: 188,
							marginBottom: 8,
							display: "block",
						}}
					/>
					<Space>
						<Button
							type="primary"
							onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
							icon={<SearchOutlined />}
							size="small"
							style={{ width: 90 }}
						>
							Search
						</Button>
						<Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
							Reset
						</Button>
					</Space>
				</div>
			),
			filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
			render: (text) => text,
		}
	}
	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm()
		if (selectedKeys[0]) setSearchObj({ ...searchObj, [dataIndex]: selectedKeys[0] })
	}

	const handleReset = (clearFilters, dataIndex) => {
		clearFilters()
		delete searchObj[dataIndex]
		setSearchObj({ ...searchObj })
	}

	const [stkUpdateModalVisible, setStkUpdateModalVisible] = React.useState(false)
	const [confirmModalLoading, setModalLoading] = React.useState(false)
	const [modalSearchValue, setModalSearchValue] = useState("")
	const [selectedMailLogRecord, setSelectedMailLogRecord] = useState({})
	const [selectedStockist, setSelectedStockist] = useState(null)

	const resetModal = () => {
		setModalSearchValue("")
		setResult([])
		setSelectedStockist(null)
		setSelectedMailLogRecord({})
	}

	const showModal = (record) => {
		resetModal()
		setSelectedMailLogRecord(record)
		setStkUpdateModalVisible(true)
	}

	const handleOk = () => {
		if (selectedStockist === null) {
			return failMessage("Please select a stockist to update")
		}
		setModalLoading(true)
		updateStockist({
			client: selectedClient,
			record: selectedMailLogRecord,
			selectedStockist,
		})
			.then((msg) => successMessage(msg))
			.catch((e) => failMessage(e.message))
			.finally(() => {
				setStkUpdateModalVisible(false)
				setModalLoading(false)
				// trigger table update
				setSearchObj({})
			})
	}

	const handleCancel = () => {
		setStkUpdateModalVisible(false)
		resetModal()
	}

	const [result, setResult] = useState([])
	const handleStockistSearch = (searchText) => {
		getStockistEntries({
			client: selectedClient,
			filter: {
				text: searchText,
			},
		})
			.then((response) => {
				setResult(response)
			})
			.catch((e) => {
				setResult([])
				failMessage(e.message)
			})
	}
	const onChange = (changedValue) => {
		setSelectedStockist(null)
		setModalSearchValue(changedValue)
	}
	const onSelectedStockist = (selectedValue) => {
		setSelectedStockist(selectedValue)
	}
	const columns = [
		{
			title: "Action",
			key: "action",
			width: 200,
			render: (text, record) => {
				return (
					<div className="action-container">
						<div className="action-button">
							<Button type="primary" onClick={showModal.bind(this, record)} size="small">
								Update Stockist Mapping
							</Button>
						</div>
					</div>
				)
			},
		},
	].concat(
		constants.MAIL_LOG_COLUMNS.map((e) => ({
			...e,
			...getColumnSearchProps(e.title),
		}))
	)

	columns[0].fixed = "left"
	columns[1].fixed = "left"

	return (
		<div>
			<div
				style={{
					display: "flex",
					paddingBottom: "15px",
					justifyContent: "space-between",
				}}
			>
				<Dropdown overlay={menu}>
					{/* eslint-disable-next-line */}
					<a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
						Change Client <DownOutlined />
					</a>
				</Dropdown>
				<Typography.Title strong>{selectedClient.name} Mail Log</Typography.Title>
				<div>
					<RangePicker
						value={dates}
						onChange={(changedDates) => {
							if (changedDates) setDates(changedDates)
						}}
					/>
				</div>
			</div>
			<Space />
			<Table
				bordered={true}
				columns={columns}
				dataSource={rows}
				pagination={{
					position: ["bottomCenter"],
					onChange: (page) => setPageNo(page),
					onShowSizeChange: (current, size) => setPageSize(size),
					current: currentPageNo,
					pageSize: currentPageSize,
					total: currentTotal,
				}}
				loading={loading}
				scroll={{ x: 1500 }}
			/>
			<Modal
				title="Update Stockist Id"
				visible={stkUpdateModalVisible}
				onOk={handleOk}
				confirmLoading={confirmModalLoading}
				onCancel={handleCancel}
			>
				<Text keyboard>{"Currently Mapped Stk Id:"}</Text>
				<Text keyboard>{selectedMailLogRecord.stockistId ? selectedMailLogRecord.stockistId : "N/A"}</Text>
				<br />
				<br />
				<AutoComplete
					style={{
						width: 200,
					}}
					value={modalSearchValue}
					options={result.map((e) => ({ value: e }))}
					onSearch={handleStockistSearch}
					onSelect={onSelectedStockist}
					onChange={onChange}
					placeholder="Search Stockist Id"
				></AutoComplete>
			</Modal>
		</div>
	)
}
export default MailLog
