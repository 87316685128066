import React, { useContext } from "react"

import LoginForm from "./LoginForm"
import SideBarNav from "./SideBarNav"
import { GlobalStoreContext } from "./Store"

const Provider = (props) => {
	const { authToken } = useContext(GlobalStoreContext)
	return !authToken ? <LoginForm /> : <SideBarNav />
}

export default Provider
