const MAIL_LOG_COLUMNS = [
	{
		title: "uuid",
		dataIndex: "uuid",
		width: 350,
		key: "uuid",
	},
	{
		title: "extension",
		dataIndex: "extension",
		width: 150,
		key: "extension",
	},
	{
		title: "sender",
		dataIndex: "sender",
		width: 300,
		key: "sender",
	},
	{
		title: "date",
		dataIndex: "date",
		width: 200,
		key: "date",
	},
	{
		title: "subject",
		dataIndex: "subject",
		width: 300,
		key: "subject",
	},
	{
		title: "originalFilename",
		dataIndex: "originalFilename",
		width: 300,
		key: "originalFilename",
	},
	{
		title: "filename",
		dataIndex: "filename",
		width: 400,
		key: "filename",
	},
	{
		title: "_id",
		dataIndex: "_id",
		width: 300.0,
		key: "_id",
	},
	{
		title: "remarks",
		dataIndex: "remarks",
		width: 300,
		key: "remarks",
	},
	{
		title: "stockistId",
		dataIndex: "stockistId",
		width: 150,
		key: "stockistId",
	},
	{
		title: "stockistName",
		dataIndex: "stockistName",
		width: 350,
		key: "stockistName",
	},
	{
		title: "matchedMetaDataId",
		dataIndex: "matchedMetaDataId",
		width: 300.0,
		key: "matchedMetaDataId",
	},
	{
		title: "matchedMetaData",
		dataIndex: "matchedMetaData",
		width: 300.0,
		key: "matchedMetaData",
	},
	{
		title: "extractedOn",
		dataIndex: "extractedOn",
		width: 300.0,
		key: "extractedOn",
	},
	{
		title: "splitFilesLink",
		dataIndex: "splitFilesLink",
		width: 300.0,
		key: "splitFilesLink",
	},
	{
		title: "converted_file_uuid",
		dataIndex: "converted_file_uuid",
		width: 300.0,
		key: "converted_file_uuid",
	},
	{
		title: "hide",
		dataIndex: "hide",
		width: 300.0,
		key: "hide",
	},
	{
		title: "duplicateOf",
		dataIndex: "duplicateOf",
		width: 300.0,
		key: "duplicateOf",
	},
	{
		title: "mapped",
		dataIndex: "mapped",
		width: 300.0,
		key: "mapped",
	},
	{
		title: "finalThresholdId",
		dataIndex: "finalThresholdId",
		width: 300.0,
		key: "finalThresholdId",
	},
	{
		title: "numPages",
		dataIndex: "numPages",
		width: 300.0,
		key: "numPages",
	},
	{
		title: "reintroduced_link",
		dataIndex: "reintroduced_link",
		width: 300.0,
		key: "reintroduced_link",
	},
	{
		title: "metaUsed",
		dataIndex: "metaUsed",
		width: 300.0,
		key: "metaUsed",
	},

	{
		title: "reintroduced",
		dataIndex: "reintroduced",
		width: 300.0,
		key: "reintroduced",
	},
	{
		title: "hash",
		dataIndex: "hash",
		width: 300.0,
		key: "hash",
	},
	{
		title: "originalFileUUID",
		dataIndex: "originalFileUUID",
		width: 300.0,
		key: "originalFileUUID",
	},
	{
		title: "processedBy",
		dataIndex: "processedBy",
		width: 300.0,
		key: "processedBy",
	},
]

const cols = {
	MAIL_LOG_COLUMNS
}

export default cols
