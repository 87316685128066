// const isBuildVersion = process.env.NODE_ENV === "production"
const isBuildVersion = true

const LOCALHOST_URL = "http://localhost:7777"
const BASE_AUTH_URL = isBuildVersion ? "https://indoco.orderstack.xyz" : LOCALHOST_URL

const INDOCO_API_URL = isBuildVersion ? "https://indoco.orderstack.xyz" : LOCALHOST_URL
const GUFIC_API_URL = isBuildVersion ? "https://gufic.orderstack.xyz" : LOCALHOST_URL
const SANOFI_API_URL = isBuildVersion ? "https://sanofi.orderstack.xyz" : LOCALHOST_URL
const ALKEM_API_URL = isBuildVersion ? "https://alkem.orderstack.xyz" : LOCALHOST_URL
const MEDLEY_API_URL = isBuildVersion ? "https://medley.orderstack.xyz" : LOCALHOST_URL

const SSE_CLIENTS = [
	{
		name: "Sanofi",
		hostUrl: SANOFI_API_URL,
		env: "sanofi",
		logUrls: [
			{
				name: "Sanofi Server 1",
				path: "/server_1_logs",
			},
			{
				name: "Sanofi Server 2",
				path: "/server_2_logs",
			},
		],
		criticalStockists: [],
	},
	{
		name: "Indoco",
		hostUrl: INDOCO_API_URL,
		env: "indoco",
		logUrls: [
			{
				name: "Indoco Server 1",
				path: "/server_1_logs",
			},
			{
				name: "Indoco Server 2",
				path: "/server_2_logs",
			},
		],
		criticalStockists: ["RAH004_RA1001", "KAM074_KA1002"],
	},
	{
		name: "Gufic",
		hostUrl: GUFIC_API_URL,
		env: "gufic",
		logUrls: [
			{
				name: "Gufic Server",
				path: "/logs",
			},
		],
		criticalStockists: [],
	},
	{
		name: "Alkem",
		hostUrl: ALKEM_API_URL,
		env: "alkem",
		logUrls: [
			{
				name: "Alkem Server 1",
				path: "/server_1_logs",
			},
			{
				name: "Alkem Server 2",
				path: "/server_2_logs",
			},
		],
		criticalStockists: [],
	},
	{
		name: "Medley",
		hostUrl: MEDLEY_API_URL,
		env: "medley",
		logUrls: [
			{
				name: "Medley Server 1",
				path: "/server_1_logs",
			},
			{
				name: "Medley Server 2",
				path: "/server_2_logs",
			},
		],
		criticalStockists: [],
	},
]

const paths = {
	LOGIN: BASE_AUTH_URL + "/common/v0.1/login",
	LOGOUT: BASE_AUTH_URL + "/common/v0.1/logout",
	SSE_CLIENTS,
	CRON_JOB_STATUS_PATH: "/utility/v0.1/get-cron-job-status",
	MAIL_LOG_ROWS_PATH: "/mailmeta/v0.1/mail-log",
	STOCKIST_SEARCH: "/stockist/v0.1/",
	UNIDENTIFIED_UPDATE: "/utility/v0.1/stockist-mapping",
	WRONG_MAPPING_UPDATE: "/utility/v0.1/correct-mapping",
}
export default paths
