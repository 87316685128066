import "./SideBarNav.css"

import React, { useContext, useState } from "react"
import { Layout, Menu, Typography, Col, Button, Popover } from "antd"
import { Route, Switch, Link, Redirect } from "react-router-dom"
import { UserOutlined, LogoutOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons"

// get global state context
import { GlobalStoreContext } from "../Store"

// get all routes
import navRoutes from "../routes.js"

const { Header, Sider, Content } = Layout

const SideBarNav = (props) => {
	// get the auth token and title text from global store
	const { authToken, userData, menuToolBarTitle, handleLogOut } = useContext(GlobalStoreContext)

	const [collapsed, setCollapsed] = useState(true)
	const toggle = () => {
		setCollapsed(!collapsed)
	}

	return (
		<Layout>
			{/* If authToken becomes null or gets set to null then redirect to "/" for login page render */}
			{!authToken ? <Redirect exact push to="/" /> : ""}

			<Sider trigger={null} collapsible collapsed={collapsed} theme="light" position="fixed">
				<div className="logo" />
				<Menu mode="inline" defaultSelectedKeys={["1"]}>
					{navRoutes
						.filter((e) => e.availableToUserTypes.includes(userData.type))
						.map((_item, index) => (
							<Menu.Item key={index + 1} icon={_item.icon}>
								<Link to={_item.path}>{_item.title}</Link>
							</Menu.Item>
						))}
				</Menu>
			</Sider>
			<Layout className="site-layout">
				<Header className="site-layout-background" style={{ display: "flex", justifyContent: "space-between" }}>
					<Col align="middle">
						{React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
							className: "trigger",
							onClick: toggle,
						})}
					</Col>
					<Col align="middle">
						<Typography.Text style={{ fontSize: "1rem" }} strong>
							{menuToolBarTitle}
						</Typography.Text>
					</Col>
					<div style={{ flexGrow: 1 }}></div>
					<Col align="middle">
						<Popover
							placement="bottom"
							title={null}
							content={
								<Button icon={<LogoutOutlined />} onClick={handleLogOut}>
									Logout
								</Button>
							}
							trigger="click"
						>
							<Button type="text" size={"large"} icon={<UserOutlined />}>
								{"Hi, "}
								{userData.name.charAt(0).toUpperCase() + userData.name.slice(1)}
							</Button>
						</Popover>
					</Col>
				</Header>
				<Content className="site-layout-background content-background">
					<Switch>
						{navRoutes
							.filter((e) => e.availableToUserTypes.includes(userData.type))
							.map((ITEM, index) => (
								<Route
									path={ITEM.path}
									key={index + ITEM.path}
									exact
									render={() => <ITEM.component {...ITEM.props} />}
								></Route>
							))}
					</Switch>
				</Content>
			</Layout>
		</Layout>
	)
}

export default SideBarNav
