import React from "react"
import {
	// DashboardOutlined,
	FileTextOutlined,
	SearchOutlined,
} from "@ant-design/icons"

import LogDisplay from "./Pages/LogDisplay"
import MailLog from "./Pages/MailLog"
import SpecificMailLog from "./Pages/SpecificMailLog"
import ManageStockist from "./Pages/ManageStockist"

const routes = [
	{
		icon: <SearchOutlined />,
		title: "Search Mail Log",
		path: "/",
		component: MailLog,
		availableToUserTypes: [10, 30],
		props: {},
	},
	{
		icon: <SearchOutlined />,
		title: "Search Critical Stockist Files",
		path: "/critical-stockists",
		component: SpecificMailLog,
		availableToUserTypes: [10, 30],
		props: {},
	},
	{
		icon: <FileTextOutlined />,
		title: "Show Server Logs",
		path: "/logs",
		component: LogDisplay,
		availableToUserTypes: [10],
		props: {},
	},
	{
		icon: <FileTextOutlined />,
		title: "Add Stockist Entry",
		path: "/add-stockist",
		component: ManageStockist,
		availableToUserTypes: [10],
		props: {},
	},
]

export default routes
