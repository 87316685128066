import "antd/dist/antd.css"

import React from "react"
import { HashRouter as Router } from "react-router-dom"
import { message } from "antd"

import "./App.css"

import Provider from "./Provider"
import { GlobalStoreProvider } from "./Store"

message.config({
	top: 30,
	maxCount: 3,
})

function App() {
	return (
		/* for enabling global store */
		<GlobalStoreProvider>
			{/* For enabling hash routing */}
			<Router>
				<Provider />
			</Router>
		</GlobalStoreProvider>
	)
}

export default App
