import React, { useState, useContext, useEffect } from "react"
import { Row, Input, Button, Col, Typography } from "antd"
import { UserOutlined, EyeTwoTone, EyeInvisibleOutlined, LockOutlined } from "@ant-design/icons"

// get global state context
import { GlobalStoreContext } from "../Store"

import packageJson from "../../package.json"

const LoginForm = (props) => {
	// local data variables for login form
	const [username, setUsername] = useState("")
	const [password, setPassword] = useState("")

	// login loader animation status
	const [startLoaderAnim, setStartLoaderAnim] = useState(false)

	// get the api call function from store
	const { postLogin, failMessage } = useContext(GlobalStoreContext)

	// listens to only the unmount event
	useEffect(() => {
		return function cleanup() {
			// on unmount reset the loader state
			setUsername("")
			setPassword("")
			setStartLoaderAnim(false)
		}
	}, [])

	const onSubmitEvent = () => {
		if (!username) return failMessage("Username is empty")
		if (!password) return failMessage("Password is empty")
		setStartLoaderAnim(true)

		// replace any further routing
		window.location.replace("/#/")

		// make the login request
		postLogin({
			username,
			password,
		}).catch((e) => {
			setStartLoaderAnim(false)
		})
	}

	return (
		<>
			<Row justify="space-around" align="middle" style={{ height: "100vh" }}>
				<Col align="middle" style={{ minWidth: "630px" }}>
					<Typography.Title strong style={{ color: "black" }}>
						Secondary Sales Status v{packageJson.version}
					</Typography.Title>
					<Input
						size="large"
						placeholder="enter your username"
						value={username}
						onChange={(e) => setUsername(e.target.value)}
						prefix={<UserOutlined />}
					/>
					<Input.Password
						placeholder="enter your password"
						size="large"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
						prefix={<LockOutlined />}
					/>
					<br />
					<br />
					<Button type="primary" loading={startLoaderAnim} onClick={onSubmitEvent}>
						Login
					</Button>
				</Col>
			</Row>
		</>
	)
}

export default LoginForm
