import "./ManageStockist.css"

import { Button, Dropdown, Menu, Space, Typography, message } from "antd"
import { DownOutlined } from "@ant-design/icons"
import React, { useState } from "react"
import JSONInput from "react-json-editor-ajrm"
import locale from "react-json-editor-ajrm/locale/en"
import apiConstants from "../../api"

const paramsMapper = require("../../helpers/paramsMapper")

const stockistPlaceHolder = {
	stockist_code: "0000000000",
	name: "THE NODAL OFFICERI-ADONI",
	email: "abc@gmail.com",
	gst_no: "37HYDE02915D1DZ",
	line_1: "C/CDS5, NEAR PARTHAPURAM ROAD",
	line_2: "ADONI",
	city: "ADONI",
	state: "ANDHRA PRADESH",
	custom: [],
	valid: 1,
	_do_not_extract: false,
	additional_emails: [],
	cnf_emails: [],
}

const allStockistMetaFields = [
	"stockist_code",
	"name",
	"email",
	"gst_no",
	"line_1",
	"line_2",
	"city",
	"state",
	"custom",
	"valid",
	"_do_not_extract",
	"additional_emails",
	"cnf_emails",
]

const ManageStockist = (props) => {
	const [selectedClient, changeClient] = useState({ name: "None" })

	const [finalStockistObj, setFinalStockistObj] = useState(false)

	const handleClientChange = function (e) {
		changeClient(apiConstants.SSE_CLIENTS.find((c) => c.name === e.key.split("_")[0]))
	}
	const clientMenu = (
		<Menu>
			{apiConstants.SSE_CLIENTS.map((e, idx) => {
				return (
					<Menu.Item key={e.name + "_" + idx} onClick={handleClientChange}>
						{e.name}
					</Menu.Item>
				)
			})}
		</Menu>
	)
	const validateAndInsertStockist = function () {
		if (selectedClient.name === "None") {
			message.error({
				duration: 3,
				content: "Select target client",
			})
			return
		}

		if (finalStockistObj === false || JSON.stringify(stockistPlaceHolder) === JSON.stringify(finalStockistObj)) {
			message.error({
				duration: 3,
				content: "Please edit the placeholder values",
			})
			return
		}

		for (let key of Object.keys(finalStockistObj)) {
			if (!allStockistMetaFields.includes(key)) {
				message.error({
					duration: 3,
					content: `Unknown field: ${key}`,
				})
				return
			}
		}

		const data = paramsMapper(finalStockistObj, {
			stockist_code: "string",
			name: "string",
			email: {
				type: "string",
				allowEmpty: true,
			},
			gst_no: {
				type: "string",
				allowEmpty: true,
			},
			line_1: {
				type: "string",
				allowEmpty: true,
			},
			line_2: {
				type: "string",
				allowEmpty: true,
			},
			city: {
				type: "string",
				allowEmpty: true,
			},
			state: {
				type: "string",
				allowEmpty: true,
			},
			custom: "array",
			valid: "number",
			_do_not_extract: "boolean",
			additional_emails: "array",
			cnf_emails: "array",
		})

		if (data.missingFields && data.missingFields.length) {
			message.error({
				duration: 3,
				content: `Invalid value/s or missing: ${data.missingFields.join(", ")}`,
			})
			return
		}

		if (
			/^0+$/gi.test(finalStockistObj.stockist_code) ||
			(/^\d+$/g.test(finalStockistObj.stockist_code) && finalStockistObj.stockist_code.length !== 10) ||
			(!/^\d+$/g.test(finalStockistObj.stockist_code) &&
				!/^[a-z0-9]+_[a-z0-9]+$/gi.test(finalStockistObj.stockist_code))
		) {
			message.error({
				duration: 3,
				content: "Invalid value in stockist_code",
			})
			return
		}

		if (finalStockistObj.email !== "" && !/\S+@\S+\.\S+/gi.test(finalStockistObj.email)) {
			message.error({
				duration: 3,
				content: "Invalid value in email",
			})
			return
		}

		if (finalStockistObj.gst_no !== "" && finalStockistObj.gst_no.length !== 15) {
			message.error({
				duration: 3,
				content: "Invalid value in gst_no",
			})
			return
		}

		for (let arr of finalStockistObj.custom) {
			if (!Array.isArray(arr)) {
				message.error({
					duration: 3,
					content: "Invalid value in custom",
				})
				return
			}
			if (arr.length === 0) {
				message.error({
					duration: 3,
					content: "Invalid value in custom",
				})
				return
			}
			for (let elem of arr) {
				if (typeof elem !== "string") {
					message.error({
						duration: 3,
						content: "Invalid value in custom",
					})
					return
				}
			}
		}

		if (![1, 0].includes(finalStockistObj.valid)) {
			message.error({
				duration: 3,
				content: "Invalid value in 'valid'",
			})
			return
		}

		for (let elem of finalStockistObj.additional_emails) {
			if (typeof elem !== "string" || !/\S+@\S+\.\S+/gi.test(elem)) {
				message.error({
					duration: 3,
					content: "Invalid value in additional_emails",
				})
				return
			}
		}

		for (let elem of finalStockistObj.cnf_emails) {
			if (typeof elem !== "string" || !/\S+@\S+\.\S+/gi.test(elem)) {
				message.error({
					duration: 3,
					content: "Invalid value in cnf_emails",
				})
				return
			}
		}

		// if (finalStockistObj.email !== "") {
		// 	finalStockistObj._valid_email = true
		// } else {
		// 	finalStockistObj._valid_email = false
		// }
	}

	return (
		<div>
			<Space direction="vertical" size="large">
				<Typography.Text strong>Target Client: {selectedClient.name}</Typography.Text>
				<Space direction="horizontal" size="large">
					<Dropdown overlay={clientMenu}>
						{/* eslint-disable-next-line */}
						<a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
							Change Client <DownOutlined />
						</a>
					</Dropdown>
					<Button onClick={validateAndInsertStockist}>Validate and Insert Stockist</Button>
				</Space>
				<Typography.Title level={3}>JSON Editor</Typography.Title>
				<JSONInput
					id="a_unique_id"
					placeholder={stockistPlaceHolder}
					colors={{
						string: "#DAA520",
					}}
					onBlur={(obj) => {
						if (obj.error === false) {
							setFinalStockistObj(obj.jsObject)
						} else {
							setFinalStockistObj(false)
						}
					}}
					onChange={(obj) => {
						if (obj.error === false) {
							setFinalStockistObj(obj.jsObject)
						} else {
							setFinalStockistObj(false)
						}
					}}
					locale={locale}
					height="550px"
					width="1000px"
					style={{ contentBox: { fontSize: "20px" } }}
				/>
			</Space>
		</div>
	)
}
export default ManageStockist
