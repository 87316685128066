import React, { useContext, useEffect, useState } from "react"
import { Menu, Dropdown, Table, Input, Button, Space, Typography, Modal } from "antd"
import { DownOutlined } from "@ant-design/icons"
import { DatePicker } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import moment from "moment-timezone"

// get global state context
import { GlobalStoreContext } from "../../Store"
import apiConstants from "../../api"
import constants from "../../constants"
const { RangePicker } = DatePicker

const MailLog = () => {
	// get mutations and state variables from global state
	const { setMenuToolBarTitle, getMailLogRows, failMessage } = useContext(GlobalStoreContext)

	const [selectedClient, changeClient] = useState(apiConstants.SSE_CLIENTS[0])
	const [rows, setRows] = useState([])
	const [dates, setDates] = useState([moment().tz("Asia/Kolkata").subtract(1, "days"), moment().tz("Asia/Kolkata")])
	const [searchObj, setSearchObj] = useState({})
	const [loading, toggleLoading] = useState(true)
	const [currentPageNo, setPageNo] = useState(1)
	const [currentPageSize, setPageSize] = useState(7)
	const [currentTotal, setCurrentTotal] = useState(0)
	const [isModalVisible, setIsModalVisible] = useState(false)

	// when component mounted
	useEffect(() => {
		// set the menu tool bar title text
		setMenuToolBarTitle("Search Critical Mail Log")
	}, [setMenuToolBarTitle])

	const handleMenuItemClick = function (e) {
		changeClient(apiConstants.SSE_CLIENTS.find((c) => c.name === e.key.split("_")[0]))
	}

	// watcher
	useEffect(() => {
		toggleLoading(true)
		let mounted = true
		getMailLogRows({
			client: selectedClient,
			filter: { ...searchObj, stockistIds: selectedClient.criticalStockists },
			dates,
			pagination: { currentPageNo, currentPageSize },
		})
			.then((response) => {
				if (mounted) {
					setRows(
						response.rows.map((e) => {
							e.hide = e.hide !== undefined ? String(e.hide) : "false"
							e.mapped = e.mapped !== undefined ? String(e.mapped) : "false"
							e.reintroduced = e.reintroduced !== undefined ? String(e.reintroduced) : "false"
							e.date = moment(e.date).tz("Asia/Kolkata").format("DD MMMM YYYY HH:mm:ss")
							return e
						})
					)
					setCurrentTotal(response.total)
					toggleLoading(false)
				}
			})
			.catch((e) => {
				if (mounted) {
					failMessage(e.message)
					toggleLoading(false)
				}
			})

		return () => (mounted = false)
		// eslint-disable-next-line
	}, [dates, selectedClient, searchObj, currentPageNo, currentPageSize])

	const menu = (
		<Menu>
			{apiConstants.SSE_CLIENTS.map((e, idx) => {
				return (
					<Menu.Item key={e.name + "_" + idx} onClick={handleMenuItemClick}>
						{e.name}
					</Menu.Item>
				)
			})}
		</Menu>
	)

	const getColumnSearchProps = (dataIndex) => {
		if (dataIndex === "date") return null
		return {
			filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
				<div style={{ padding: 8 }}>
					<Input
						placeholder={`Search ${dataIndex}`}
						value={selectedKeys[0]}
						onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
						onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
						style={{
							width: 188,
							marginBottom: 8,
							display: "block",
						}}
					/>
					<Space>
						<Button
							type="primary"
							onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
							icon={<SearchOutlined />}
							size="small"
							style={{ width: 90 }}
						>
							Search
						</Button>
						<Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
							Reset
						</Button>
					</Space>
				</div>
			),
			filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
			render: (text) => text,
		}
	}
	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm()
		if (selectedKeys[0]) setSearchObj({ ...searchObj, [dataIndex]: selectedKeys[0] })
	}

	const handleReset = (clearFilters, dataIndex) => {
		clearFilters()
		delete searchObj[dataIndex]
		setSearchObj({ ...searchObj })
	}

	const columns = constants.MAIL_LOG_COLUMNS.map((e) => ({
		...e,
		...getColumnSearchProps(e.title),
	}))

	columns[0].fixed = "left"

	const showModal = () => {
		setIsModalVisible(true)
	}

	const handleOk = () => {
		setIsModalVisible(false)
	}

	const handleCancel = () => {
		setIsModalVisible(false)
	}

	return (
		<div>
			<div
				style={{
					display: "flex",
					paddingBottom: "15px",
					justifyContent: "space-between",
				}}
			>
				<Dropdown overlay={menu}>
					{/* eslint-disable-next-line */}
					<a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
						Change Client <DownOutlined />
					</a>
				</Dropdown>

				<Button onClick={showModal} size="small">
					Show Critical Stockist List
				</Button>

				<Typography.Title type="danger" strong>
					{selectedClient.name} Critical Stockists Log
				</Typography.Title>
				<div>
					<RangePicker
						value={dates}
						onChange={(changedDates) => {
							if (changedDates) setDates(changedDates)
						}}
					/>
				</div>
			</div>
			<Space />
			<Modal title="Basic Modal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
				{selectedClient.criticalStockists.map(function (object, i) {
					return <p>{object}</p>
				})}
			</Modal>
			<Table
				bordered={true}
				columns={columns}
				dataSource={rows}
				pagination={{
					position: ["bottomCenter"],
					onChange: (page) => setPageNo(page),
					onShowSizeChange: (current, size) => setPageSize(size),
					current: currentPageNo,
					pageSize: currentPageSize,
					total: currentTotal,
				}}
				loading={loading}
				scroll={{ x: 1500 }}
			/>
		</div>
	)
}
export default MailLog
