import React, { createContext, useState } from "react"
import axios from "axios"
import { message } from "antd"
import socketIOClient from "socket.io-client"

import apiConstants from "../api"

const initialState = {
	authToken: window.sessionStorage.getItem("authToken")
		? JSON.parse(window.sessionStorage.getItem("authToken"))
		: null,
	userData: window.sessionStorage.getItem("userData") ? JSON.parse(window.sessionStorage.getItem("userData")) : null,
	menuToolBarTitle: null,
}

const failMessage = (msg) => {
	console.error(msg)
	message.error({
		content: msg,
	})
}

const successMessage = (msg) => {
	message.success({
		content: msg,
	})
}

export const GlobalStoreContext = createContext(initialState)
export const GlobalStoreProvider = ({ children }) => {
	const [authToken, setAuthToken] = useState(initialState.authToken)
	const [userData, setUserData] = useState(initialState.userData)
	const [menuToolBarTitle, setMenuToolBarTitle] = useState(initialState.menuToolBarTitle)

	const makeApiCall = function (partConfig) {
		return new Promise((resolve, reject) => {
			if (!authToken) {
				reject("no auth token found")
			}
			axios({
				...partConfig,
				headers: {
					Authorization: authToken,
				},
			})
				.then((response) => {
					let data = response.data
					resolve(data)
				})
				.catch((err) => {
					console.log("[HTTP API Request Error]", err)
					if (err.message === "Network Error") {
						failMessage("Network Error")
					} else {
						failMessage("Api call failed")
					}
					reject({
						ok: false,
						message: "Error connecting to server",
					})
				})
		})
	}

	const getStatusForJob = function ({ client, jobName }) {
		return new Promise(async (resolve, reject) => {
			const data = await makeApiCall({
				url: client.hostUrl + apiConstants.CRON_JOB_STATUS_PATH,
				method: "get",
				params: {
					jobName,
				},
			}).catch((e) => {
				return reject(e)
			})
			if (data && data.ok) {
				return resolve(data)
			} else {
				return reject(new Error("fetch failed"))
			}
		})
	}

	const getMailLogRows = function ({ client, filter, dates, pagination }) {
		return new Promise(async (resolve, reject) => {
			const data = await makeApiCall({
				url: client.hostUrl + apiConstants.MAIL_LOG_ROWS_PATH,
				method: "get",
				params: {
					...filter,
					startDate: dates[0].toISOString(),
					endDate: dates[1].toISOString(),
					pageNo: pagination.currentPageNo,
					pageSize: pagination.currentPageSize,
				},
			}).catch((e) => {
				return reject(e)
			})
			if (data && data.ok) {
				return resolve(data)
			} else {
				return reject(new Error("fetch failed"))
			}
		})
	}

	const getStockistEntries = function ({ client, filter }) {
		return new Promise(async (resolve, reject) => {
			const data = await makeApiCall({
				url: client.hostUrl + apiConstants.STOCKIST_SEARCH,
				method: "get",
				params: {
					...filter,
					pageNo: 1,
					pageSize: 10,
				},
			}).catch((e) => {
				return reject(e)
			})
			if (data && data.ok) {
				return resolve(Object.keys(data.stockists))
			} else {
				return reject(new Error("fetch failed"))
			}
		})
	}

	const updateStockist = function ({ client, record, selectedStockist }) {
		return new Promise(async (resolve, reject) => {
			const data = await makeApiCall({
				url:
					client.hostUrl +
					(record.stockistId ? apiConstants.WRONG_MAPPING_UPDATE : apiConstants.UNIDENTIFIED_UPDATE),
				method: "put",
				data: {
					uuid: record.uuid,
					...(!record.stockistId && {
						stockistId: selectedStockist,
					}),
					...(record.stockistId && {
						wrongStockistId: record.stockistId,
						correctStockistId: selectedStockist,
					}),
				},
			}).catch((e) => {
				return reject(e)
			})
			if (data && data.ok) {
				resolve("Stockist update success")
			} else {
				reject(new Error("Stockist update failed"))
			}
		})
	}

	const getSocketClientConnection = function ({ server, hostUrl }) {
		return socketIOClient(hostUrl, {
			path: server.path,
			query: `token=${authToken}`,
		})
	}

	function parseJwt(token) {
		var base64Url = token.split(".")[1]
		var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
		var jsonPayload = decodeURIComponent(
			window
				.atob(base64)
				.split("")
				.map(function (c) {
					return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
				})
				.join("")
		)

		return JSON.parse(jsonPayload)
	}

	const postLogin = function ({ username, password }) {
		return new Promise((resolve, reject) => {
			axios
				.post(apiConstants.LOGIN, { username, password })
				.then((response) => {
					let data = response.data
					if (!data.ok) {
						failMessage(data.message || "Login Failed")
						reject(new Error(data.message))
					} else {
						data.mortal = parseJwt(data.token)
						if (!data.mortal.sseStatusWebAppAccess) {
							failMessage("Access forbidden")
							return reject(new Error("Status webapp access not granted"))
						}
						// save in sessionStorage
						window.sessionStorage.setItem("authToken", JSON.stringify(data.token))
						window.sessionStorage.setItem(
							"userData",
							JSON.stringify({ ...data.mortal, ...data.mortal.userData })
						)

						/* logical success */
						setUserData({ ...data.mortal, ...data.mortal.userData })
						setAuthToken(data.token)

						successMessage("Login was successful")
						resolve()
					}
				})
				.catch((err) => {
					console.log("[Error] login", err)
					if (err.message === "Network Error") {
						failMessage("Network Error")
					} else {
						failMessage("Login Failed")
					}
					reject(err)
				})
		})
	}

	const handleLogOut = function () {
		setAuthToken(null)
		setUserData(null)
		window.sessionStorage.setItem("authToken", null)
		window.sessionStorage.setItem("userData", null)
	}

	return (
		<GlobalStoreContext.Provider
			value={{
				// state variables
				authToken,
				userData,
				menuToolBarTitle,
				// mutations
				postLogin,
				handleLogOut,
				setMenuToolBarTitle,
				// triggers
				failMessage,
				successMessage,
				//custom functions
				getSocketClientConnection,
				getStatusForJob,
				getMailLogRows,
				getStockistEntries,
				updateStockist,
			}}
		>
			{children}
		</GlobalStoreContext.Provider>
	)
}
