import "./LogDisplay.css"

import React, { useContext, useEffect, useState } from "react"
import { Menu, Dropdown, Typography } from "antd"
import { DownOutlined } from "@ant-design/icons"
import { LazyLog, ScrollFollow } from "react-lazylog"

// get global state context
import apiConstants from "../../api"
import { GlobalStoreContext } from "../../Store"

const LogDisplay = () => {
	// get mutations and state variables from global state
	const { setMenuToolBarTitle, getSocketClientConnection } = useContext(GlobalStoreContext)

	const [logLines, setLogLines] = useState("Please wait for the log lines")
	const [selectedClient, changeClient] = useState(JSON.parse(JSON.stringify(apiConstants.SSE_CLIENTS[0])))
	const [selectedServer, changeServer] = useState(JSON.parse(JSON.stringify(apiConstants.SSE_CLIENTS[0].logUrls[0])))

	useEffect(() => {
		setMenuToolBarTitle(selectedClient.name + " Logs")
		setLogLines(selectedServer.name)
		const socket = getSocketClientConnection({ server: selectedServer, hostUrl: selectedClient.hostUrl })
		socket.on("data", (data) => {
			setLogLines((logLines) => {
				return logLines + "\n" + data
			})
		})
		return function cleanup() {
			socket.close()
		}
	}, [selectedServer, selectedClient, setMenuToolBarTitle, getSocketClientConnection])

	const handleClientChange = function (e) {
		changeClient(apiConstants.SSE_CLIENTS.find((c) => c.name === e.key.split("_")[0]))
		changeServer(apiConstants.SSE_CLIENTS.find((c) => c.name === e.key.split("_")[0]).logUrls[0])
	}

	const handleServerChange = function (e) {
		changeServer(selectedClient.logUrls.find((c) => c.name === e.key.split("_")[0]))
	}

	const clientMenu = (
		<Menu>
			{apiConstants.SSE_CLIENTS.map((e, idx) => {
				return (
					<Menu.Item key={e.name + "_" + idx} onClick={handleClientChange}>
						{e.name}
					</Menu.Item>
				)
			})}
		</Menu>
	)

	const serverMenu = (
		<Menu>
			{selectedClient.logUrls.map((e, idx) => {
				return (
					<Menu.Item key={e.name + "_" + idx} onClick={handleServerChange}>
						{e.name}
					</Menu.Item>
				)
			})}
		</Menu>
	)

	return (
		<>
			<div className="custom-dropdown">
				<Typography.Text strong>Displaying: {selectedServer.name}</Typography.Text>
				<Dropdown overlay={clientMenu}>
					{/* eslint-disable-next-line */}
					<a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
						Change Client <DownOutlined />
					</a>
				</Dropdown>
				<Dropdown overlay={serverMenu}>
					{/* eslint-disable-next-line */}
					<a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
						Change Server <DownOutlined />
					</a>
				</Dropdown>
			</div>

			<div
				style={{
					// border: "solid 1px black",
					borderRadius: "10px",
					boxShadow: "0px 3px 15px rgba(0,0,0,0.2)",
					minHeight: "750px",
					padding: "15px",
					backgroundColor: "#f7f7f7",
				}}
			>
				<ScrollFollow
					startFollowing={true}
					render={({ follow, onScroll }) => (
						<LazyLog
							extraLines={1}
							enableSearch
							caseInsensitive
							text={logLines}
							follow={follow}
							onScroll={onScroll}
						/>
					)}
				/>
			</div>
		</>
	)
}
export default LogDisplay
