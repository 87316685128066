const typeOf = function (Item) {
	if (typeof Item == "object") {
		if (Array.isArray(Item)) {
			return "array"
		} else {
			return "object"
		}
	}
	return typeof Item
}

const mapper = function (obj, skeleton) {
	// let newObj = false
	let missingFields = false
	let missingOne = (key) => {
		if (!missingFields) {
			missingFields = []
		}
		missingFields.push(key)
	}
	// let addOne = (key, Item) => {
	// 	if (!newObj) {
	// 		newObj = {}
	// 	}
	// 	newObj[key] = Item
	// }
	for (let key in skeleton) {
		let allowEmpty = false
		if (typeof skeleton[key] === "object") {
			allowEmpty = skeleton[key].allowEmpty
			skeleton[key] = skeleton[key].type
		}
		let typeExpected = skeleton[key]
		let typeIs = typeOf(obj[key], false)
		if (!obj.hasOwnProperty(key) || (obj[key] === "" && !allowEmpty)) {
			missingOne(key)
		} else if (typeExpected !== typeIs) {
			missingOne(key)
		} else {
			if (typeExpected === "object") {
				let mapped = mapper(obj[key], skeleton[key])
				if (mapped.newObj) {
					// addOne(key, mapped.newObj)
				}
				if (mapped.missingFields) {
					mapped.missingFields.forEach((k) => missingOne(k))
				}
			} else {
				// addOne(key, obj[key])
			}
		}
	}
	// return { newObj, missingFields }
	return { missingFields }
	/* : ((missingFields == {}) ? null : missingFields)  */
}
module.exports = mapper
